<i18n lang="json5">{
	ru: {
		working_time: 'с 9:00 до 23:00 без выходных',
		avia: 'Авиабилеты',
		train: 'Поезд',
		bus: 'Автобус',
		insurance: 'Страхование',
		hotel: 'Отель',
		transfer: 'Трансфер',
		business_lounge: 'Бизнес-зал',
		search_box_show: 'Раскрыть форму поиска',
		search_box_hide: 'Свернуть форму поиска',
	},
	uz: {
		working_time: 'Soat 9 dan 23:00 gacha, dam olish kunisiz',
		avia: 'Aviachiptalar',
		train: 'Poyezd',
		bus: 'Avtobus',
		insurance: 'Sugʼurta',
		hotel: 'Mehmonxona',
		transfer: 'Transfer',
		business_lounge: 'Biznes zali',
		search_box_show: 'Qidirish shaklini ochish',
		search_box_hide: 'Qidiruv shaklini berkitish',
	}
}</i18n>

<template>
	<div>
		<div class="bg-my-black px-4 sm:px-8 py-4 shadow-md">
			<div class="flex justify-between xl:container xl:mx-auto">
				<div class="flex-[0_0_auto] align-middle">
					<nuxt-link :to="{name: 'home'}" class="inline-block align-middle">
						<img
							v-if="isNewYear"
							src="/design/logo-new-year.png"
							class="w-32 sm:w-44 lg:w-52"
							width="923"
							height="156"
							alt=""
						/>
						<img
							v-else
							src="/design/logo-w.png"
							class="w-32 sm:w-44 lg:w-52"
							width="400"
							height="76"
							alt=""
						/>
					</nuxt-link>

					<div class="hidden lg:inline-block ml-6 pl-14 border-l border-my-gray text-white font-bold align-bottom relative">
						<div class="text-xl leading-none">+998 (71) 200 48 00</div>
						<div class="text-xs">{{ $t('working_time') }}</div>
						<img src="/design/phone-call.svg" class="w-5 absolute top-0 left-6" alt>
					</div>
				</div>

				<div class="flex-[0_0_auto] hidden lg:block align-middle" v-if="$route.name !== 'avia-landing'">
					<div class="flex border-t border-my-gray">
						<div
							class="inner-page-header-switch ml-0 px-0 pt-2"
							:class="{'inner-page-header-switch-active': active === 'avia'}"
							@click="active = 'avia'"
						>
							{{ $t('avia') }}
						</div>
						<div class="inner-page-header-switch"
							:class="{'inner-page-header-switch-active': active === 'train'}"
							@click="active = 'train'"
							v-if="false"
						>
							{{ $t('train') }}
						</div>
						<div
							class="inner-page-header-switch"
							:class="{'inner-page-header-switch-active': active === 'bus'}"
							v-if="false"
						>
							{{ $t('bus') }}
						</div>
						<div
							class="inner-page-header-switch px-0 pt-2"
							:class="{'inner-page-header-switch-active': active === 'insurance'}"
							@click="active = 'insurance'"
						>
							{{ $t('insurance') }}
						</div>
						<div
							class="inner-page-header-switch"
							v-if="false"
						>
							{{ $t('hotel') }}
						</div>
						<div
							class="inner-page-header-switch"
							v-if="false"
						>
							{{ $t('transfer') }}
						</div>
						<div
							class="inner-page-header-switch"
							v-if="false"
						>
							{{ $t('business_lounge') }}
						</div>
					</div>
				</div>

				<div class="flex-[0_0_auto] text-right align-top">
					<div class="flex items-center pl-2">
						<LanguageSwitcher />
						<div class="relative" v-click-outside="onClickOutside">
							<img
								src="/design/user.svg"
								class="h-10 inline-block cursor-pointer"
								width="40"
								height="40"
								@click="showUserBox = !showUserBox"
								alt
							/>
							<HeaderUserBox :show="showUserBox" />
						</div>
						<div class="ml-6">
							<img
								src="/design/hamburger.svg"
								class="inline-block cursor-pointer"
								width="46"
								height="26"
								alt
								@click="$emit('toggleSidebar')"
							/>
						</div>
					</div>

					<div class="hidden lg:inline-block text-white font-bold text-lg lg:ml-10" v-if="false">
						<span class="">Узб</span>
					</div>

					<nuxt-link :to="{name: 'home'}" class="inline-block ml-10" v-if="false">
						<img src="/design/top-menu.svg" class="w-12" alt />
					</nuxt-link>
				</div>
			</div>
		</div>
		<template v-if="$route.name !== 'avia-landing'">
			<div
				class="relative z-20 bg-my-blue-light p-6 shadow-md"
				:class="{
					'lg:pb-14': active === 'avia',
					'lg:py-8': active === 'insurance'
				}"
				v-show="showSearchBox"
			>
				<div class="xl:container xl:mx-auto" v-if="$route.name !== 'train-offers' && $route.name !== 'train-booking-page'">
					<keep-alive>
						<component
							:is="currentSearchComponent"
							:requestData="requestData"
							:offers="offers"
							@refreshPage="$emit('refreshPage')"
						/>
					</keep-alive>
				</div>
			</div>
			<div class="flex justify-center">
				<div
					class="bg-my-blue-light text-white rounded-b-xl shadow-md cursor-pointer mx-auto text-center pb-1 px-5"
					@click="showSearchBox = !showSearchBox"
				>
					<span class="text-sm mr-2">
						<template v-if="!showSearchBox">{{ $t('search_box_show') }}</template>
						<template v-else>{{ $t('search_box_hide') }}</template>
					</span>
					<img
						src="/design/angle-arrow-down-white.svg"
						class="inline-block align-middle w-3"
						:class="{'show-arrow-active': showSearchBox}"
						alt
					>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
    import HeaderUserBox from './HeaderUserBox.vue'
	import AviaSearchForm from './AviaSearchForm.vue'
	import InsuranceSearchForm from './InsuranceSearchForm.vue';
	import TrainSearchForm from './TrainSearchForm.vue';
    import LanguageSwitcher from './LanguageSwitcher.vue';

    export default {
        components: {
            HeaderUserBox,
			AviaSearchForm,
			InsuranceSearchForm,
			TrainSearchForm,
	        LanguageSwitcher
        },

		props: {
			requestData: Object,
			offers: Array,
		},

        data() {
            return {
				active: 'avia',
                showSearchBox: false,
                showUserBox: false,
				isNewYear: this.$store.getters['isNewYear'],
            }
        },

		methods: {
			onClickOutside() {
				this.showUserBox = false
			}
		},

		computed: {
			currentSearchComponent: function() {
				if (this.active === 'insurance') {
					return 'InsuranceSearchForm'
				} else if (this.active === 'train') {
					return 'TrainSearchForm'
				}
				return 'AviaSearchForm'
			}
		},

		watch: {
			$route() {
				this.showUserBox = false

				if (this.active === 'insurance') {
					this.showSearchBox = false
				}
			},
			offers(offers) {
				if (this.active === 'avia') {
					this.showSearchBox = offers && offers.length === 0
				}
			},
		},
    }
</script>
