<i18n lang="json5">{
	ru: {
		countries: 'Страны',
		airlines: 'Авиакомпании',
		airports: 'Аэропорты',
		copyrights: 'Все права защищены',
		tpp: 'Член Торгово-Промышленной Палаты Узбекистана',
		apta: 'Член Ассоциации частных туристических организаций Узбекистана',
		apek: 'Член Ассоциации электронной коммерции Узбекистана',
		it_park: 'Резидент IT Парка Узбекистана',
		legal_information: 'Правовая информация',
		covid: 'COVID-19',
		help: 'Помощь',
		terms_of_use: 'Правила использования',
		offer: 'Оферта',
		privacy_policy: 'Политика конфиденциальности',
		payment: 'Оплата',
		lets_meet: 'Познакомимся?',
		about_company: 'О компании',
		contacts: 'Контакты',
		news: 'Новости',
		jobs: 'Вакансии',
		cooperation: 'Сотрудничество',
		payment_tooltip: 'Для Вашего удобства мы предоставляем возможность оплаты при помощи карт, наличными в офисе компании, банковским переводом.\nОплата по картам происходит на сайте платежных систем и защищена шифрованием',
		footer_text: 'Данные, используемые на портале Bookhara.uz, включая стоимость билетов, страховых полисов и других туристических продуктов, а также расписание рейсов, взяты из официальных источников.\nБилеты, страховые полисы и другие туристические продукты предоставляются партнерами Bookhara.uz и их стоимость указана с учетом сервисного сбора Bookhara.uz. Окончательную сумму можно увидеть на шаге подтверждения заказа.\nПри использовании материалов ссылка на сайт Bookhara.uz обязательна.',
	},
	'uz': {
		countries: 'Davlatlar',
		airlines: 'Aviakompaniyalar',
		airports: 'Aeroportlar',
		copyrights: 'Barcha huquqlar himoyalangan',
		tpp: 'Oʼzbekiston Savdo-Sanoat Palatasi aʼzosi',
		apta: 'Oʼzbekiston Xususiy Sayyohlik Tashkilotlar Assotsiatsiyasi aʼzosi',
		apek: 'Oʼzbekiston Elektron Tijorat Assotsiatsiyasi aʼzosi',
		it_park: 'IT-park Oʼzbekiston rezidenti',
		legal_information: 'Huquqiy maʼlumotlar',
		covid: 'COVID-19',
		help: 'Yordam',
		terms_of_use: 'Foydalanish shartlari',
		offer: 'Oferta',
		privacy_policy: 'Maxfiylik siyosati',
		payment: 'Toʼlov',
		lets_meet: 'Tanishamizmi?',
		about_company: 'Kompaniya haqida',
		contacts: 'Aloqa maʼlumotlari',
		news: 'Yangiliklar',
		jobs: 'Boʼsh ish oʼrinlari',
		cooperation: 'Hamkorlik',
		payment_tooltip: 'Sizning qulayligingiz uchun biz karta orqali, kompaniya ofisida naqd pul yoki bank oʼtkazmasi orqali toʼlash imkoniyatini taqdim etamiz.\nKartalar orqali toʼlovlar, toʼlov tizimlarining veb-saytida amalga oshiriladi va shifrlash bilan himoyalangan',
		footer_text: 'Bookhara.uz portalida foydalaniladigan maʼlumotlar, jumladan elektron aviachiptalar va temir yoʼl chiptalari, avtobus chiptalari, elektron sugʼurta polislari va boshqa sayyohlik mahsulotlari, shuningdek, aviareyslar, poyezdlar, avtobuslar jadvali rasmiy manbalardan olingan.\nElektron aviachiptalar va temir yoʼl chiptalari, avtobus chiptalari, elektron sugʼurta polislari va boshqa sayyohlik  mahsulotlari Bookhara.uz hamkorlari tomonidan taqdim etiladi va ularning narxi Bookhara.uz xizmati toʼlovi hisobga olingan holda koʼrsatiladi. Umumiy summani buyurtmani tasdiqlash bosqichida koʼrish mumkin.\nMateriallardan foydalanishda Bookhara.uz saytiga havola koʼrsatilishi shart.',
	}
}</i18n>

<template>
	<div class="xl:container xl:mx-auto">
		<div class="lg:flex lg:justify-between xl:w-3/4">
			<div class="">
				<img
					v-if="isNewYear"
					src="/design/logo-new-year.png"
					class="w-32 sm:w-36 lg:w-40"
					width="923"
					height="156"
					alt=""
				/>
				<img
					v-else
					src="/design/logo-w.png"
					class="w-32 sm:w-36 lg:w-40"
					width="400"
					height="76"
					alt=""
				/>
				<ul class="mt-4 text-base font-bold footer-list hidden">
					<li>{{ $t('countries') }}</li>
					<li>{{ $t('airlines') }}</li>
					<li>{{ $t('airports') }}</li>
				</ul>
				<div class="mt-4 align-top text-sm">
					&copy; 2021-{{ currentYear }}. {{ $t('copyrights') }}
				</div>
				<div class="flex space-x-4 items-center mt-4">
					<div class="w-32">
						<img
							src="/design/tpp.png"
							width="250"
							height="78"
							v-tooltip="$t('tpp')"
							:alt="$t('tpp')"
						/>
					</div>
					<div class="w-24">
						<img
							src="/design/apta.svg"
							width="175"
							height="93"
							v-tooltip="$t('apta')"
							:alt="$t('apta')"
						/>
					</div>
				</div>
				<div class="flex space-x-4 items-center mt-4">
					<div class="w-32">
						<img
							src="/design/apek.png"
							width="300"
							height="52"
							v-tooltip="$t('apek')"
							:alt="$t('apek')"
						/>
					</div>
					<div class="w-24">
						<img
							src="/design/it-park.svg"
							width="300"
							height="101"
							v-tooltip="$t('it_park')"
							:alt="$t('it_park')"
						/>
					</div>
				</div>
			</div>

			<div class="mt-10 lg:mt-0">
				<h3 class="mb-3 lg:mb-4 font-bold text-xl">
					{{ $t('legal_information') }}
				</h3>
				<ul class="lg:text-sm footer-list mb-4">
<!--					<li class="mb-1.5 lg:mb-0">-->
<!--						<nuxt-link-->
<!--							:to="{name: 'covid'}"-->
<!--							class="-m-0.5 p-0.5 lg:hover:underline"-->
<!--						>-->
<!--							{{ $t('covid') }}-->
<!--						</nuxt-link>-->
<!--					</li>-->
					<li v-if="false">
						{{ $t('help') }}
					</li>
					<li class="mb-1.5 lg:mb-0">
						<nuxt-link
							:to="{name: 'terms-of-use'}"
							class="-m-0.5 p-0.5 lg:hover:underline"
						>
							{{ $t('terms_of_use') }}
						</nuxt-link>
					</li>
					<li class="mb-1.5 lg:mb-0">
						<nuxt-link
							:to="{name: 'public-offer'}"
							class="-m-0.5 p-0.5 lg:hover:underline"
						>
							{{ $t('offer') }}
						</nuxt-link>
					</li>
					<li class="mb-1.5 lg:mb-0">
						<nuxt-link
							:to="{name: 'privacy-policy'}"
							class="-m-0.5 p-0.5 lg:hover:underline"
						>
							{{ $t('privacy_policy') }}
						</nuxt-link>
					</li>
					<li class="mb-1.5 lg:mb-0">
						<nuxt-link
							:to="{name: 'payment'}"
							class="-m-0.5 p-0.5 lg:hover:underline"
						>
							{{ $t('payment') }}
						</nuxt-link>
					</li>
				</ul>
				<template v-if="$route.name === 'home'">
					<div
						class="inline-block lg:hidden -mb-2 lg:mb-0"
						v-tooltip="$t('payment_tooltip')"
					>
						<img src="/design/humo.svg" class="inline-block mr-2 mb-2 w-auto h-5" alt />
						<img src="/design/uzcard.svg" class="inline-block mr-2 mb-2 w-auto h-5" alt />
						<img src="/design/mastercard.svg" class="inline-block mr-2 mb-2 w-auto h-5" alt />
						<img src="/design/visa.svg" class="inline-block mr-2 mb-2 w-auto h-5" alt />
						<img src="/design/mir.svg" class="inline-block mr-2 mb-2 w-auto h-5" alt />
						<img src="/design/bank.svg" class="inline-block mr-2 mb-2 w-auto h-5" alt />
						<img src="/design/cash.svg" class="inline-block mr-2 mb-2 w-auto h-5" alt />
						<img src="/design/paynet.svg" class="inline-block mb-2 w-auto h-5 opacity-75" alt />
					</div>
					<img
						src="/design/payment-methods.svg"
						class="hidden lg:inline-block w-auto h-7"
						alt
						v-tooltip="$t('payment_tooltip')"
					/>
				</template>
				<template v-else>
					<img
						src="/design/payment-methods.svg"
						class="inline-block w-auto h-7"
						alt
						v-tooltip="$t('payment_tooltip')"
					/>
				</template>
			</div>

			<div class="mt-10 lg:mt-0">
				<h3 class="mb-3 lg:mb-4 font-bold text-xl">
					{{ $t('lets_meet') }}
				</h3>
				<ul class="lg:text-sm footer-list">
					<li class="mb-1.5 lg:mb-0">
						<nuxt-link
							:to="{name: 'about-company'}"
							class="-m-0.5 p-0.5 lg:hover:underline"
						>
							{{ $t('about_company') }}
						</nuxt-link>
					</li>
					<li class="mb-1.5 lg:mb-0">
						<nuxt-link
							:to="{name: 'contacts'}"
							class="-m-0.5 p-0.5 lg:hover:underline"
						>
							{{ $t('contacts') }}
						</nuxt-link>
					</li>
					<li v-if="false">
						{{ $t('news') }}
					</li>
					<li v-if="false">
						{{ $t('jobs') }}
					</li>
					<li v-if="false">
						{{ $t('cooperation') }}
					</li>
				</ul>
				<div class="mt-6">
					<a href="https://t.me/bookharauz" target="_blank">
						<img src="/design/telegram.svg" class="inline-block w-9 h-auto" width="35" height="35" alt />
					</a>
					<a href="https://facebook.com/Bookhara.Official/" target="_blank" class="ml-4">
						<img src="/design/facebook.svg" class="inline-block w-9 h-auto" width="35" height="35" alt />
					</a>
					<a href="https://www.instagram.com/bookhara_official/" target="_blank" class="ml-4">
						<img src="/design/insta.svg" class="inline-block w-9 h-auto" width="35" height="35" alt />
					</a>
				</div>
			</div>
		</div>
		<div
			class="mt-6 text-xs text-gray-400 whitespace-pre-line"
			v-if="$route.name !== 'home'"
		>
			{{ $t('footer_text') }}
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FooterContent',
		data() {
			return {
				isNewYear: this.$store.getters['isNewYear'],
			}
		},
		computed: {
			currentYear() {
				return new Date(this.$store.getters['serverDate']).getFullYear()
			}
		}
	}
</script>
